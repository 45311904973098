import React from "react"
import { graphql } from "gatsby"
import { Fade } from "react-awesome-reveal";
import Layout from "../components/layout"
import Seo from "../components/seo"
import GoogleMapReact from "google-map-react"
import ContactInfo from "../../content/settings/contact_info.yml"

const ContactUsPage = ({ data }) => {

  const title = data.markdownRemark.frontmatter.title;
  const bannerTitle = data.markdownRemark.frontmatter.banner.small_title;
  const bannerCaption = data.markdownRemark.frontmatter.banner.caption;

  return (
    <Layout>
      <Seo title={title} description={bannerCaption} />
      <section className="banner contact-us-banner">
        <div className="google-map-wrapper">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDJhuk7sf-MUxGKfcznuas9ppzMa8Jioro' }}
            defaultCenter={{ lat: -45.880340, lng: 170.509874 }}
            defaultZoom={15}
            options={{ zoomControl: false, fullscreenControl: false }}
          />
        </div>
        <div className="banner-overlay-horizontal" />
        <div className="banner-overlay-vertical" />
        <div className="container">
          <div className="row">
            <div className="col">
              <Fade triggerOnce>
                <p>{bannerCaption}</p>
                <h1>{bannerTitle}</h1>
              </Fade>
            </div>
            <div className="col">
              <div className="form-tile">
                <Fade triggerOnce>
                  <div className="contact-form-wrapper">
                    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                      <input type="hidden" aria-label="Bot Field" name="bot-field" />
                      <input type="hidden" aria-label="Form Name" name="form-name" value="contact" />
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="name">Name
                              <input type="text" name="name" required id="name" aria-label="Name" />
                            </label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label htmlFor="email">Email
                              <input type="email" name="email" required id="email" aria-label="Email" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="subject">Subject
                          <input type="text" name="subject" id="subject" aria-label="Subject" />
                        </label>
                      </div>
                      <div className="form-group">
                        <label htmlFor="message">Message
                          <textarea name="message" required id="message" aria-label="Message" rows="3" />
                        </label>
                      </div>
                      <div className="form-group actions">
                        <button className="btn-inverted" type="submit" aria-label="Submit Form">Send</button>
                        <input type="reset" aria-label="Clear Form" value="Clear" />
                      </div>
                    </form>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-content-wrapper">
        <Fade triggerOnce>
          <a href="tel:034778250" className="contact-detail">
            <div className="media">
              <svg height="48" width="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path fill="#dba989" d="M43,1H23a4,4,0,0,0-4,4V25a1,1,0,0,0,1,1,.988.988,0,0,0,.581-.187L27.32,21H43a4,4,0,0,0,4-4V5A4,4,0,0,0,43,1ZM32,15H26a1,1,0,0,1,0-2h6a1,1,0,0,1,0,2Zm8-6H26a1,1,0,0,1,0-2H40a1,1,0,0,1,0,2Z" />
                <path fill="#162c3b" d="M31,23V39a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H17V5H8A4,4,0,0,0,4,9V43a4,4,0,0,0,4,4H29a4,4,0,0,0,4-4V23Z" />
              </svg>
              <div className="media-body">
                <h3>Phone</h3>
                <p>{ContactInfo.phone}</p>
              </div>
            </div>
          </a>
          <a href="mailto:enquiries@tudorclothing.co.nz" className="contact-detail">
            <div className="media">
              <svg height="48" width="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path fill="#dba989" d="M31,13H17c-0.553,0-1-0.447-1-1s0.447-1,1-1h14c0.553,0,1,0.447,1,1S31.553,13,31,13z" />
                <path fill="#dba989" d="M31,21H17c-0.553,0-1-0.447-1-1s0.447-1,1-1h14c0.553,0,1,0.447,1,1S31.553,21,31,21z" />
                <path fill="#dba989" d="M11,25.9V3h26v13v9.9l9.2-5L39,15.5V2c0-0.6-0.4-1-1-1H10C9.4,1,9,1.4,9,2v13.7l-7.2,5.2L11,25.9z" />
                <path fill="#162c3b" d="M24.5,34.9C24.3,35,24.2,35,24,35s-0.3,0-0.5-0.1L1,22.8V45c0,1.105,0.895,2,2,2h42c1.105,0,2-0.895,2-2 V22.8L24.5,34.9z" />
              </svg>
              <div className="media-body">
                <h3>Email</h3>
                <p>{ContactInfo.email}</p>
              </div>
            </div>
          </a>
          <a href="https://goo.gl/maps/SStav8whve1xgnwF7" target="_blank" rel="noreferrer" className="contact-detail">
            <div className="media">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                <path fill="#dba989" d="M45.988,21a1,1,0,0,1-.632-.226L23.988,3.292,2.621,20.774a1,1,0,0,1-1.266-1.548l22-18a1,1,0,0,1,1.266,0l22,18A1,1,0,0,1,45.988,21Z" />
                <path fill="#162c3b" d="M26.988,33.634c0-6.656,5.593-10.634,11-10.634a11.543,11.543,0,0,1,4,.726l0-3.126-18-14.724L5.988,20.6V43a2,2,0,0,0,2,2h24.9C30.193,41.923,26.988,37.458,26.988,33.634Z" />
                <path fill="#dba989" d="M37.988,25c-4.424,0-9,3.229-9,8.634,0,5.015,7.459,12.278,8.31,13.09a1,1,0,0,0,1.381,0c.85-.812,8.309-8.075,8.309-13.09C46.988,28.229,42.412,25,37.988,25Zm0,12a3,3,0,1,1,3-3A3,3,0,0,1,37.988,37Z" />
              </svg>
              <div className="media-body">
                <h3>Address</h3>
                <p>{ContactInfo.address.street_address}, {ContactInfo.address.city}</p>
              </div>
            </div>
          </a>
        </Fade>
      </section>
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "contact-us" } }) {
    frontmatter {
      title
      banner {
        small_title
        caption
      }
      form {
        title
        caption
      }
    }
  }
}
`

export default ContactUsPage
